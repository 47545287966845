import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { ref, uploadBytes } from "firebase/storage";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import RichTextEditor from "../components/RichTextEditor";
import ImageUploader from "../components/ImageUploader";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import { validation } from "../utils/validation";
import { getCountries } from "../utils/getCountries";
import notify from "../utils/notify";
import { updateAccount, getAccountImage } from "../api";
import { storage } from "../services/firebase";

const EditProfile = () => {
  const [loading, setLoading] = useState(true);
  const { auth, profileView, isAuthenticated, getUser } = useContext(
    AuthContext
  );
  const {
    industries,
    currencies,
    englishProficiencyLevels,
    experienceLevels,
    allSkills,
    getSkills,
    getCategories,
  } = useContext(DataContext);

  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();
  const {
    register: registerTalent,
    errors: errorsTalent,
    handleSubmit: handleSubmitTalent,
    reset: resetTalent,
    control: controlTalent,
    setValue: setValueTalent,
  } = useForm();
  const countries = getCountries();

  // Get skills from subcategories
  const fetchSkills = async () => {
    await getCategories();
    await getSkills();
    await isAuthenticated();

    setLoading(false);
  };

  useEffect(() => {
    fetchSkills();
    // await isAuthenticated();
    // setLoading(false);
    return () => setLoading(false);
  }, []);

  const onImageUploadChange = (data) => {
    setValue("logo", data[0].file);
  };

  const onTalentImageUploadChange = (data) => {
    setValueTalent("picture", data[0].file);
  };

  const fetchUserData = async (accountId) => {
    await getUser(accountId);
  };

  const onEditorStateChange = (data) => {
    setValueTalent("bio", data);
  };
  const onEditorStateChangeDescription = (data) => {
    setValue("description", data);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const account_id = localStorage.getItem("account_id");
      const country = data.country.label;
      const industry = data.industry.label;
      const description = data.description;

      let imageUrl = auth?.client?.imageUrl ? auth.client.imageUrl : "";

      if (data.logo) {
        const imageRef = ref(storage, `logos/${account_id}`);
        await uploadBytes(imageRef, data.logo);
        // console.log("Logo upload successful");
        // Get logo url
        imageUrl = await getAccountImage(account_id);
      }

      const formData = _.omit(
        data,
        "country",
        "logo",
        "industry",
        "description"
      );
      const payload = {
        account_id,
        firstname: auth?.client?.firstname,
        lastname: auth?.client?.lastname,
        email: auth?.client?.email,
        created: auth?.client?.created || Date.now(),
        status: auth?.client?.status,
        country,
        imageUrl,
        industry,
        description,
        ...formData,
      };
      // console.log(payload);

      const response = await updateAccount(account_id, profileView, payload);
      if (response) {
        reset();
        notify("Profile updated successfully", "success");
        setTimeout(async () => {
          await fetchUserData(account_id);
          navigate("/dashboard-main", { replace: true });
        }, 1000);
      }

      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  const onTalentSubmit = async (data) => {
    setSubmitting(true);
    try {
      const account_id = localStorage.getItem("account_id");
      const country = data.country.label;
      const experience = data.experience.label;
      const english = data.english.label;
      const skills = data.skills.map(({ label }) => label);
      const currency = data.currency.label;
      const educationCountry = data.educationCountry.label;

      let imageUrl = auth?.talent?.imageUrl ? auth.talent.imageUrl : "";

      if (data.picture) {
        const imageRef = ref(storage, `images/${account_id}`);
        await uploadBytes(imageRef, data.picture);
        imageUrl = await getAccountImage(account_id);
      }

      const formData = _.omit(data, [
        "country",
        "experience",
        "skills",
        "english",
        "currency",
        "picture",
        "educationCountry",
      ]);
      const payload = {
        account_id,
        firstname: auth?.talent?.firstname,
        lastname: auth?.talent?.lastname,
        email: auth?.talent?.email,
        created: auth?.talent?.created,
        status: auth?.talent?.status,
        featured: auth?.talent?.featured,
        country,
        experience,
        english,
        skills,
        currency,
        imageUrl,
        educationCountry,
        workExperience: auth?.talent?.workExperience
          ? auth?.talent?.workExperience
          : [],
        ...formData,
      };

      const response = await updateAccount(account_id, profileView, payload);
      if (response) {
        resetTalent();
        notify("Profile updated successfully", "success");
        setTimeout(async () => {
          await fetchUserData(account_id);
          navigate("/dashboard-main", { replace: true });
        }, 500);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                {loading ? (
                  <Skeleton
                    height={100}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Update your {profileView ? profileView : null} Profile
                  </h3>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-7 col-lg-7 col-md-7">
                {loading ? (
                  <div className="">
                    <Skeleton
                      height={180}
                      containerClassName="w-50 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="mb-8"
                    />
                  </div>
                ) : profileView === "Client" ? (
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-8">
                      <label
                        htmlFor="logo"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Logo
                      </label>
                      {auth?.client?.imageUrl ? (
                        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap">
                          <div>
                            <img
                              src={auth?.client?.imageUrl}
                              alt={`${auth?.client?.company} devshop profile`}
                              height={200}
                            />
                          </div>
                          <div className="ml-sm-12 mt-6 mt-sm-0">
                            <Controller
                              name="logo"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              as={
                                <ImageUploader
                                  onImageUploadChange={onImageUploadChange}
                                />
                              }
                            />
                            <span className="text-danger small">
                              {errors?.logo?.message || ""}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Controller
                            name="logo"
                            control={control}
                            defaultValue=""
                            rules={validation.logo}
                            as={
                              <ImageUploader
                                onImageUploadChange={onImageUploadChange}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.logo?.message || ""}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="accountid"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Wallet ID*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.account_id}
                        disabled
                        id="accountid"
                        name="accountid"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="firstname"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        First Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.firstname}
                        disabled
                        id="firstname"
                        name="firstname"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="lastname"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Last Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.lastname}
                        disabled
                        id="lastname"
                        name="lastname"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="email"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.email}
                        disabled
                        id="email"
                        name="email"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="company"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.company || ""}
                        id="company"
                        name="company"
                        ref={register(validation.company)}
                      />
                      <span className="text-danger small">
                        {errors?.company?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="description"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Description*
                      </label>

                      <Controller
                        name="description"
                        control={control}
                        defaultValue={auth?.client?.description || ""}
                        as={
                          <RichTextEditor
                            onEditorStateChange={onEditorStateChangeDescription}
                            initialValue={auth?.client?.description || ""}
                            placeholder="Enter Company description in RichText"
                            ref={register(validation.companyDescription)}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.description?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="website"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Website
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.website || ""}
                        placeholder="Enter Company Website URL (Optional)"
                        id="website"
                        name="website"
                        ref={register(validation.website)}
                      />
                      <span className="text-danger small">
                        {errors?.website?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="linkedIn"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        LinkedIn Profile
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.linkedin || ""}
                        placeholder="Enter LinkedIn profile URL (Optional)"
                        id="linkedin"
                        name="linkedin"
                        ref={register(validation.linkedin)}
                      />
                      <span className="text-danger small">
                        {errors?.linkedin?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="gihub"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Github URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.github || ""}
                        placeholder="Enter Github profile URL (Optional)"
                        id="github"
                        name="github"
                        ref={register(validation.companyGithub)}
                      />
                      <span className="text-danger small">
                        {errors?.github?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="twitter"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Twitter Profile
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.twitter || ""}
                        placeholder="Enter Twitter profile URL (Optional)"
                        id="twitter"
                        name="twitter"
                        ref={register(validation.twitter)}
                      />
                      <span className="text-danger small">
                        {errors?.twitter?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="industry"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Industry*
                      </label>
                      <Controller
                        name="industry"
                        control={control}
                        defaultValue={
                          industries[
                            industries.findIndex(
                              ({ label }) => label === auth?.client?.industry
                            )
                          ] || ""
                        }
                        rules={validation.industry}
                        as={
                          <Select
                            options={industries}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />

                      <span className="text-danger small">
                        {errors?.industry?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="address"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Location*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.address}
                        id="address"
                        name="address"
                        ref={register(validation.location)}
                      />
                      <span className="text-danger small">
                        {errors?.address?.message || ""}
                      </span>
                    </div>
                    <div className="form-group mb-8">
                      <label
                        htmlFor="city"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        City*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.client?.city}
                        id="city"
                        name="city"
                        ref={register(validation.city)}
                      />
                      <span className="text-danger small">
                        {errors?.city?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="country"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Country*
                      </label>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue={
                          countries[
                            countries.findIndex(
                              ({ label }) => label === auth?.client?.country
                            )
                          ]
                        }
                        rules={validation.country}
                        as={
                          <Select
                            options={countries}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.country?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium rounded-5 text-uppercase w-100 focus-reset"
                        disabled={submitting ? true : false}
                      >
                        {submitting ? "Updating profile..." : "Update Profile"}
                      </button>
                    </div>

                    <div className="form-group mb-8">
                      {errors &&
                        Object.values(errors).map((field, index) => (
                          <p key={index} className="text-danger small m-0 p-0">
                            {field.message}
                          </p>
                        ))}
                    </div>
                  </form>
                ) : (
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmitTalent(onTalentSubmit)}
                  >
                    <div className="form-group mb-8">
                      <label
                        htmlFor="picture"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Profile Picture*
                      </label>
                      {auth?.talent?.imageUrl ? (
                        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap">
                          <div>
                            <img
                              src={auth?.talent?.imageUrl}
                              alt={`${auth?.talent?.firstname} devshop profile`}
                              height={190}
                            />
                          </div>
                          <div className="ml-sm-12 mt-6 mt-sm-0">
                            <Controller
                              name="picture"
                              control={controlTalent}
                              defaultValue=""
                              rules={{
                                required: false,
                              }}
                              as={
                                <ImageUploader
                                  onImageUploadChange={
                                    onTalentImageUploadChange
                                  }
                                />
                              }
                            />
                            <span className="text-danger small">
                              {errorsTalent?.picture?.message || ""}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Controller
                            name="picture"
                            control={controlTalent}
                            defaultValue=""
                            rules={validation.picture}
                            as={
                              <ImageUploader
                                onImageUploadChange={onTalentImageUploadChange}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errorsTalent?.picture?.message || ""}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="account_id"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Wallet ID*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.account_id}
                        disabled
                        id="account_id"
                        name="account_id"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="firstname"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        First Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.firstname}
                        disabled
                        id="firstname"
                        name="firstname"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="lastname"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Last Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.lastname}
                        disabled
                        id="lastname"
                        name="lastname"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="email"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.email}
                        disabled
                        id="email"
                        name="email"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="title"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Title*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.title}
                        id="title"
                        name="title"
                        ref={registerTalent(validation.title)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.title?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="bio"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Bio*
                      </label>
                      <Controller
                        name="bio"
                        control={controlTalent}
                        defaultValue=""
                        rules={validation.bio}
                        as={
                          <RichTextEditor
                            onEditorStateChange={onEditorStateChange}
                            initialValue={auth?.talent?.bio}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.bio?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="skills"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Skills*
                      </label>
                      <Controller
                        name="skills"
                        control={controlTalent}
                        defaultValue={allSkills.filter((item) =>
                          auth?.talent?.skills.includes(item.label)
                        )}
                        as={
                          <Select
                            options={allSkills}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center w-100 form-control pt-8 pb-22 border-bottom-0 multi-select-overflow basic-multi-select"
                            classNamePrefix="select"
                            border={false}
                            isMulti
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.skills?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="otherSkills"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Other skills
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Other Skills e.g (Near Blockchain, Business Analyst)"
                        id="otherskills"
                        name="otherSkills"
                        defaultValue={auth?.talent?.otherSkills}
                        ref={registerTalent(validation.otherSkills)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.otherSkills?.message || ""}
                      </span>
                    </div>

                    <div className="form-group mb-8">
                      <label
                        htmlFor="experience"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Experience Level*
                      </label>
                      <Controller
                        name="experience"
                        control={controlTalent}
                        defaultValue={
                          experienceLevels[
                            experienceLevels.findIndex(
                              ({ label }) => label === auth?.talent?.experience
                            )
                          ]
                        }
                        rules={validation.experience}
                        as={
                          <Select
                            options={experienceLevels}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                            id="experience"
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.experience?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="education"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Education Details*
                      </label>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="education"
                        className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Degree Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.educationDegree || ""}
                        id="educationDegree"
                        name="educationDegree"
                        ref={registerTalent(validation.educationDegreeName)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.degree?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <div>
                        <label
                          htmlFor="education"
                          className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                        >
                          University Name
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.educationUniversity || ""}
                        id="educationUniversity"
                        name="educationUniversity"
                        ref={registerTalent(validation.educationUniversity)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.university?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <div>
                        <label
                          htmlFor="education"
                          className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Year of Completion
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="MM-YYYY"
                        defaultValue={auth?.talent?.educationYear || ""}
                        id="educationYear"
                        name="educationYear"
                        ref={registerTalent(validation.educationYear)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.educationYear?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <div>
                        <label
                          htmlFor="education"
                          className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Country of study
                        </label>
                      </div>
                      <Controller
                        name="educationCountry"
                        control={controlTalent}
                        defaultValue={
                          countries[
                            countries.findIndex(
                              ({ label }) =>
                                label === auth?.talent?.educationCountry || ""
                            )
                          ]
                        }
                        rules={validation.country}
                        as={
                          <Select
                            options={countries}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.educationCountry?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="english"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        English Proficiency*
                      </label>
                      <Controller
                        name="english"
                        control={controlTalent}
                        defaultValue={
                          englishProficiencyLevels[
                            englishProficiencyLevels.findIndex(
                              ({ label }) => label === auth?.talent?.english
                            )
                          ]
                        }
                        rules={validation.english}
                        as={
                          <Select
                            options={englishProficiencyLevels}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.english?.message || ""}
                      </span>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                      <div className="form-group">
                        <label
                          htmlFor="rate"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Hourly Rate*
                        </label>
                        <input
                          type="number"
                          min={20}
                          className="form-control"
                          defaultValue={auth?.talent?.rate}
                          id="rate"
                          name="rate"
                          ref={registerTalent(validation.rate)}
                        />
                        <span className="text-danger small">
                          {errorsTalent?.rate?.message || ""}
                        </span>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="currency"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Currency*
                        </label>
                        <Controller
                          name="currency"
                          control={controlTalent}
                          defaultValue={
                            currencies[
                              currencies.findIndex(
                                ({ label }) => label === auth?.talent?.currency
                              )
                            ]
                          }
                          rules={validation.currency}
                          as={
                            <Select
                              options={currencies}
                              className="pl-0 arrow-3 arrow-3-black min-width-px-284 text-black-2 d-flex align-items-center form-control"
                              border={false}
                              id="currency"
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errorsTalent?.currency?.message || ""}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="hoursavailable"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Hours of Availability*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={auth?.talent?.hoursavailable}
                        id="hoursavailable"
                        name="hoursavailable"
                        ref={registerTalent(validation.hoursavailable)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.hoursavailable?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="address"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Location*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.address}
                        id="address"
                        name="address"
                        ref={registerTalent(validation.location)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.address?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="city"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        City*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.city}
                        id="city"
                        name="city"
                        ref={registerTalent(validation.city)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.city?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="country"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Country*
                      </label>
                      <Controller
                        name="country"
                        control={controlTalent}
                        defaultValue={
                          countries[
                            countries.findIndex(
                              ({ label }) => label === auth?.talent?.country
                            )
                          ]
                        }
                        rules={validation.country}
                        as={
                          <Select
                            options={countries}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errorsTalent?.country?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="postalcode"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Postal Code*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.postalcode}
                        id="postalcode"
                        name="postalcode"
                        ref={registerTalent(validation.postalcode)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.postalcode?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="phone"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Phone Number*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.phone}
                        id="phone"
                        name="phone"
                        ref={registerTalent(validation.phone)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.phone?.message || ""}
                      </span>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="github"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Github Profile URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={auth?.talent?.github}
                        id="github"
                        name="github"
                        ref={registerTalent(validation.github)}
                      />
                      <span className="text-danger small">
                        {errorsTalent?.github?.message || ""}
                      </span>
                    </div>

                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium rounded-5 text-uppercase w-100 focus-reset"
                        disabled={submitting ? true : false}
                      >
                        {submitting ? "Updating profile..." : "Update Profile"}
                      </button>
                    </div>

                    <div className="form-group mb-8">
                      {errorsTalent &&
                        Object.values(errorsTalent).map((field, index) => (
                          <p key={index} className="text-danger small m-0 p-0">
                            {field.message}
                          </p>
                        ))}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default EditProfile;
